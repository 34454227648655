import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash, faEnvelope, faBook, faBrain, faFileAlt, faCommentDots,
  faMicrophone, faCamera, faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import styles from './Lesson.module.css';
import { LessonData, Section } from '../API/types';
import { fetchAllSections, fetchSectionData, updateSection, uploadImage } from '../API/API';
import Modal from 'react-modal';
import Cropper, { Area } from 'react-easy-crop';
import getCroppedImg from '../ImageCrop/cropImage'; // Import your image cropping utility

const Lesson: React.FC = () => {
  const { tableName } = useParams<{ tableName: string }>();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<LessonData[]>([]);
  const [initialTableData, setInitialTableData] = useState<LessonData[]>([]); // Store initial data
  const [apiResponse, setApiResponse] = useState<Section | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<Section>({
    Identifier: '',
    Lessons: []
  });
  const [lessons, setLessons] = useState<string[]>([]);
  const [newLessonData, setNewLessonData] = useState<LessonData>({
    Type: '',
    Video: '',
    VideoContentText: '',
    VideoTitle: '',
    PassageId: '',
    Objectives: [''], // Start with one empty objective
    LLM_Prompt: '',
    Character: '',
    ImageUrl: '',
    imageFile: null,
    croppedBlob: null,
    ItemDescribeWords: [''],
    SentenceToBeTranslated: [''],
    TranslatedSentence: [''],
    AdditionalWords: [''],
    VideoQuestions: {
      VideoUrl: '',
      Questions: [
        {
          question: '',
          options: ['', '', '', ''],
          correctOption: '',
        },
      ],
    },
    ShowAdBefore: false,
  });
  
  
  

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppingDialogOpen, setCroppingDialogOpen] = useState(false);
  const [croppingLessonIndex, setCroppingLessonIndex] = useState<number | null>(null);
  const [jsonInputError, setJsonInputError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lessons = await fetchAllSections();
        // Sort the lessons alphabetically
        const sortedLessons = lessons.sort((a, b) => a.localeCompare(b));
        setLessons(sortedLessons);
      } catch (err: any) {
        setError(err.message);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (formData.Identifier) {
          const data = await fetchSectionData(formData.Identifier);
          if (data && data.length > 0) {
            const firstSection = data[0];
            console.log('Fetched data:', firstSection);  // Log the fetched data
  
            const parsedLessons = firstSection.Lessons.map((lesson: any) => {
              console.log('ImageObjects:', lesson);
              if (lesson.Type === 'PhotoList' && lesson.ImageObjects) {
                try {
                  lesson.ImageObjects = JSON.parse(lesson.ImageObjects);
                } catch (e) {
                  console.log('Error parsing ImageObjects:', e);
                }
              }
              return lesson;
            });
  
            setApiResponse(firstSection);
            setTableData(parsedLessons);
            setInitialTableData(parsedLessons); // Store initial data
            setFormData(firstSection);
          } else {
            setApiResponse(null);
            setTableData([]);
            setInitialTableData([]); // Reset initial data if no lessons
          }
        }
      } catch (err: any) {
        setError(err.message);
      }
    };
  
    fetchData();
  }, [formData.Identifier, tableName]);
  
  

  const handleFileChange = (lessonIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Update the imageFile for the specific lesson
      setTableData((prevData) =>
        prevData.map((lesson, index) =>
          index === lessonIndex ? { ...lesson, imageFile: file } : lesson
        )
      );
      // Open cropping dialog
      setCroppingDialogOpen(true);
      // Store the lesson index for which the cropping dialog is open
      setCroppingLessonIndex(lessonIndex);
    }
  };  

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    lessonIndex?: number
  ) => {
    const { name, value } = e.target;
  
    if (lessonIndex !== undefined) {
      setTableData((prevData) =>
        prevData.map((lesson, index) => {
          if (index === lessonIndex) {
            const updatedLesson = { ...lesson, [name]: value };
            console.log(`Lesson ${lessonIndex} updated:`, updatedLesson); // Debugging line
            return updatedLesson;
          }
          return lesson;
        })
      );
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  

  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const updatedLessons = await Promise.all(
        tableData.map(async (lesson) => {
          if (lesson.Type === 'Review') {
            return {
              ...lesson,
              ReviewSection: lesson.ReviewSection || '',
              ReviewComponent: lesson.ReviewComponent || '',
              // Ensure ShowAdBefore is included
              ShowAdBefore: lesson.ShowAdBefore !== undefined ? lesson.ShowAdBefore : false,
            };
          } else if (lesson.Type === 'PhotoList') {
            let imageUrl = lesson.ImageUrl;
  
            if (lesson.croppedBlob) {
              const newFileName = `Lesson_Image_${lesson.imageFile!.name}`;
              imageUrl = await uploadImage(newFileName, lesson.croppedBlob);
            }
  
            return {
              ...lesson,
              ImageUrl: imageUrl,
              ImageObjects: Array.isArray(lesson.ImageObjects)
                ? lesson.ImageObjects
                : [],
              // Ensure ShowAdBefore is included
              ShowAdBefore: lesson.ShowAdBefore !== undefined ? lesson.ShowAdBefore : false,
            };
          } else {
            return lesson;
          }
        })
      );
  
      const updatedFormData: Section = {
        ...formData,
        Lessons: updatedLessons as LessonData[],
      };
  
      await updateSection(updatedFormData.Identifier, updatedFormData);
      setInitialTableData([...tableData]);
      setError(null);
    } catch (err: any) {
      setError(err.message);
    }
  };
  
  
  
  

  const handleAddLesson = () => {
    setTableData([...tableData, { ...newLessonData }]);
    setNewLessonData({
      Type: '',
      Video: '',
      VideoContentText: '',
      VideoTitle: '',
      PassageId: '',
      Objectives: ['', '', ''],
      LLM_Prompt: '',
      Character: '',
      ImageUrl: '',
      imageFile: null,  // Reset field
      croppedBlob: null,  // Reset field
      ItemDescribeWords: [''],
    });
  };
  

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (croppingLessonIndex !== null) {
      const lesson = tableData[croppingLessonIndex];
      if (lesson.imageFile && croppedAreaPixels) {
        const croppedBlob = await getCroppedImg(URL.createObjectURL(lesson.imageFile), croppedAreaPixels);
        const croppedFile = new File([croppedBlob], lesson.imageFile.name, { type: lesson.imageFile.type });
        // Update the croppedBlob for the specific lesson
        setTableData((prevData) =>
          prevData.map((lesson, index) =>
            index === croppingLessonIndex ? { ...lesson, croppedBlob: croppedFile } : lesson
          )
        );
        setCroppingDialogOpen(false);
        setCroppingLessonIndex(null);
      }
    }
  };

  const handleDeleteLesson = (index: number) => {
    setTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(tableData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTableData(items);
  };

  const handleJsonTextInput = (lessonIndex: number) => (input: string) => {
    try {
      const jsonData = JSON.parse(input);
  
      if (Array.isArray(jsonData) && jsonData.every(item => item.spanish && item.english)) {
        setJsonInputError(null);
        // Update the ImageObjects for the specific lesson
        setTableData((prevData) =>
          prevData.map((lesson, index) =>
            index === lessonIndex ? { ...lesson, ImageObjects: jsonData } : lesson
          )
        );
      } else {
        throw new Error("Invalid format: Each item must have 'spanish' and 'english' properties.");
      }
    } catch (err) {
      setJsonInputError("Invalid JSON format or structure. Make sure it matches [{ spanish: '...', english: '...' }, ...]");
    }
  };

  // Handler for adding a new question
  const handleAddQuestion = (lessonIndex: number) => {
    setTableData((prevData) =>
      prevData.map((lesson, index) => {
        if (index === lessonIndex) {
          return {
            ...lesson,
            VideoQuestions: {
              ...(lesson.VideoQuestions || { VideoUrl: '', Questions: [] }), // Ensure VideoQuestions exists
              Questions: [
                ...((lesson.VideoQuestions?.Questions) || []), // Ensure Questions array exists
                {
                  question: '',
                  options: ['', '', ''],
                  correctOption: ''
                }
              ]
            }
          };
        }
        return lesson;
      })
    );
  };
  


  const handleVideoQuestionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    lessonIndex: number,
    questionIndex?: number,
    optionIndex?: number,
    field?: string
  ) => {
    const { value } = e.target;
  
    setTableData((prevData) =>
      prevData.map((lesson, index) => {
        if (index === lessonIndex) {
          const updatedVideoQuestions = {
            VideoUrl: lesson.VideoQuestions?.VideoUrl || '',
            Questions: lesson.VideoQuestions?.Questions?.map((q, qIndex) =>
              qIndex === questionIndex
                ? {
                    ...q,
                    // Handle correctOption separately
                    correctOption: field === 'correctOption' ? value : q.correctOption,
                    // Handle the additional options
                    options: optionIndex !== undefined && optionIndex < 3
                      ? q.options.map((option, oIndex) =>
                          oIndex === optionIndex ? value : option
                        )
                      : q.options,
                    question: optionIndex === undefined && field !== 'correctOption' ? value : q.question,
                  }
                : q
            ) || []
          };
  
          if (field === 'videoUrl') {
            updatedVideoQuestions.VideoUrl = value;
          }
  
          return {
            ...lesson,
            VideoQuestions: updatedVideoQuestions,
          };
        }
        return lesson;
      })
    );
  };
  

  const renderAdditionalFields = (lesson: LessonData, lessonIndex: number) => {
    const handleDropdownChange = (value: boolean) => {
      setTableData((prevData) =>
        prevData.map((lesson, index) =>
          index === lessonIndex ? { ...lesson, ShowAdBefore: value } : lesson
        )
      );
    };
  
    const renderShowAdBeforeDropdown = () => (
      <div className={styles.formGroup}>
        <label className={styles.label}>
          Show Ad Before:
          <select
            name="ShowAdBefore"
            value={lesson.ShowAdBefore ? 'true' : 'false'}
            onChange={(e) => handleDropdownChange(e.target.value === 'true')}
            className={styles.input}
          >
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </label>
      </div>
    );
  
    const handleAddItemDescribeWord = () => {
      setTableData(prevData =>
        prevData.map((lessonItem, index) =>
          index === lessonIndex
            ? {
                ...lessonItem,
                ItemDescribeWords: [...lessonItem.ItemDescribeWords, ''] 
              }
            : lessonItem
        )
      );
    };
  
    const handleItemDescribeChange = (e: React.ChangeEvent<HTMLInputElement>, wordIndex: number) => {
      const { value } = e.target;
      setTableData(prevData =>
        prevData.map((lessonItem, index) =>
          index === lessonIndex
            ? {
                ...lessonItem,
                ItemDescribeWords: lessonItem.ItemDescribeWords.map((word, idx) =>
                  idx === wordIndex ? value : word
                )
              }
            : lessonItem
        )
      );
    };
  
    const handleSentenceChange = (
      e: React.ChangeEvent<HTMLInputElement>, 
      lessonIndex: number, 
      sentenceIndex: number, 
      field: 'SentenceToBeTranslated' | 'TranslatedSentence' | 'AdditionalWords'
    ) => {
      const { value } = e.target;
      setTableData((prevData) =>
        prevData.map((lessonItem, index) =>
          index === lessonIndex
            ? {
                ...lessonItem,
                [field]: lessonItem[field]?.map((sentence, i) =>
                  i === sentenceIndex ? value : sentence
                )
              }
            : lessonItem
        )
      );
    };
  
    const handleAddSentence = (lessonIndex: number) => {
      setTableData((prevData) =>
        prevData.map((lessonItem, index) =>
          index === lessonIndex
            ? {
                ...lessonItem,
                SentenceToBeTranslated: [...(lessonItem.SentenceToBeTranslated || []), ''],
                TranslatedSentence: [...(lessonItem.TranslatedSentence || []), ''],
                AdditionalWords: [...(lessonItem.AdditionalWords || []), '']
              }
            : lessonItem
        )
      );
    };
  
    const handleAddQuestion = (lessonIndex: number) => {
      setTableData((prevData) =>
        prevData.map((lessonItem, index) => {
          if (index === lessonIndex) {
            return {
              ...lessonItem,
              VideoQuestions: {
                ...(lessonItem.VideoQuestions || { VideoUrl: '', Questions: [] }),
                Questions: [
                  ...((lessonItem.VideoQuestions?.Questions) || []),
                  {
                    question: '',
                    options: ['', '', '', ''],
                    correctOption: ''
                  }
                ]
              }
            };
          }
          return lessonItem;
        })
      );
    };
  
    const handleVideoQuestionChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      lessonIndex: number,
      questionIndex?: number,
      optionIndex?: number,
      field?: string
    ) => {
      const { value } = e.target;
    
      setTableData((prevData) =>
        prevData.map((lessonItem, index) => {
          if (index === lessonIndex) {
            const updatedVideoQuestions = {
              VideoUrl: lessonItem.VideoQuestions?.VideoUrl || '',
              Questions: lessonItem.VideoQuestions?.Questions?.map((q, qIndex) =>
                qIndex === questionIndex
                  ? {
                      ...q,
                      correctOption: field === 'correctOption' ? value : q.correctOption,
                      options: optionIndex !== undefined && optionIndex < 3
                        ? q.options.map((option, oIndex) =>
                            oIndex === optionIndex ? value : option
                          )
                        : q.options,
                      question: optionIndex === undefined && field !== 'correctOption' ? value : q.question,
                    }
                  : q
              ) || []
            };
    
            if (field === 'videoUrl') {
              updatedVideoQuestions.VideoUrl = value;
            }
    
            return {
              ...lessonItem,
              VideoQuestions: updatedVideoQuestions,
            };
          }
          return lessonItem;
        })
      );
    };
  
    switch (lesson.Type) {
      case 'Message':
        return (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Video:
                <input 
                  type="text" 
                  name="Video" 
                  value={lesson.Video || ''} 
                  onChange={(e) => handleChange(e, lessonIndex)} 
                  className={styles.input} 
                />
              </label>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Video Title:
                <input
                  type="text"
                  name="VideoTitle"
                  value={lesson.VideoTitle || ''}
                  onChange={(e) => handleChange(e, lessonIndex)}
                  className={styles.input}
                />
              </label>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Video Content Text:
                <input
                  type="text"
                  name="VideoContentText"
                  value={lesson.VideoContentText || ''}
                  onChange={(e) => handleChange(e, lessonIndex)}
                  className={styles.input}
                />
              </label>
            </div>
            {renderShowAdBeforeDropdown()}
          </>
        );
      case 'Reading':
        return (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Passage Id:
                <input 
                  type="text" 
                  name="PassageId" 
                  value={lesson.PassageId || ''} 
                  onChange={(e) => handleChange(e, lessonIndex)} 
                  className={styles.input} 
                />
              </label>
            </div>
            {renderShowAdBeforeDropdown()}
          </>
        );
      case 'Conversation':
        return (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Objectives:
                <div className={styles.optionsGroup}>
                  {lesson.Objectives?.map((objective, idx) => (
                    <div key={idx} className={styles.objectiveRow}>
                      <input
                        type="text"
                        value={objective || ''}
                        onChange={(e) => {
                          const { value } = e.target;
                          setTableData((prevData) =>
                            prevData.map((lessonItem, index) =>
                              index === lessonIndex
                                ? {
                                    ...lessonItem,
                                    Objectives: (lessonItem.Objectives || []).map((obj, objIdx) =>
                                      objIdx === idx ? value : obj
                                    ),
                                  }
                                : lessonItem
                            )
                          );
                        }}
                        className={styles.input}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setTableData((prevData) =>
                            prevData.map((lessonItem, index) =>
                              index === lessonIndex
                                ? {
                                    ...lessonItem,
                                    Objectives: (lessonItem.Objectives || []).filter((_, objIdx) => objIdx !== idx),
                                  }
                                : lessonItem
                            )
                          );
                        }}
                        className={styles.removeButton}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => {
                      setTableData((prevData) =>
                        prevData.map((lessonItem, index) =>
                          index === lessonIndex
                            ? {
                                ...lessonItem,
                                Objectives: [...(lessonItem.Objectives || []), ''],
                              }
                            : lessonItem
                        )
                      );
                    }}
                    className={styles.button}
                  >
                    Add Objective
                  </button>
                </div>
              </label>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                LLM Prompt:
                <input 
                  type="text" 
                  name="LLM_Prompt" 
                  value={lesson.LLM_Prompt || ''} 
                  onChange={(e) => handleChange(e, lessonIndex)} 
                  className={styles.input} 
                />
              </label>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Character:
                <select 
                  name="Character" 
                  value={lesson.Character || ''} 
                  onChange={(e) => handleChange(e, lessonIndex)} 
                  className={styles.input}
                >
                  <option value="Pablo Picasso">Pablo Picasso</option>
                  <option value="Frida Kahlo">Frida Kahlo</option>
                </select>
              </label>
            </div>
            {renderShowAdBeforeDropdown()}
          </>
        );
      case 'PhotoList':
        return (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Upload Image:
                <input type="file" accept="image/*" onChange={handleFileChange(lessonIndex)} className={styles.input} />
              </label>
            </div>
            <div className={styles.formGroup}>
              {lesson.ImageUrl && (
                <img src={lesson.ImageUrl} alt="Lesson Image" className={styles.imagePreview} />
              )}
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Image Objects (Spanish to English pairs):
                <ul>
                  {lesson.ImageObjects?.map((obj: any, i: number) => (
                    <li key={i}>{obj.spanish} - {obj.english}</li>
                  ))}
                </ul>
              </label>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                <textarea 
                  onChange={(e) => handleJsonTextInput(lessonIndex)(e.target.value)} 
                  value={JSON.stringify(lesson.ImageObjects, null, 2)} 
                  className={styles.input} 
                />
              </label>
            </div>
            {renderShowAdBeforeDropdown()}
            {jsonInputError && <div className={styles.error}>{jsonInputError}</div>}
          </>
        );
      case 'ItemDescribe':
        return (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Item Describe Words:
                {lesson.ItemDescribeWords.map((word, i) => (
                  <div key={i} className={styles.wordRow}>
                    <input
                      type="text"
                      value={word}
                      onChange={(e) => handleItemDescribeChange(e, i)}
                      className={styles.input}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setTableData((prevData) =>
                          prevData.map((lessonItem, index) =>
                            index === lessonIndex
                              ? {
                                  ...lessonItem,
                                  ItemDescribeWords: lessonItem.ItemDescribeWords.filter(
                                    (_, wordIndex) => wordIndex !== i
                                  ),
                                }
                              : lessonItem
                          )
                        );
                      }}
                      className={styles.removeButton}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </label>
            </div>
            <button onClick={handleAddItemDescribeWord} className={styles.button}>
              Add Word
            </button>
            {renderShowAdBeforeDropdown()}
          </>
        );
      case 'Sentence_Translation':
        return (
          <>
            {lesson.SentenceToBeTranslated?.map((sentence, index) => (
              <div key={index} className={styles.formGroup}>
                <label className={styles.label}>
                  Sentence To be Translated:
                  <input
                    type="text"
                    name={`SentenceToBeTranslated_${index}`}
                    value={sentence || ''}
                    onChange={(e) => handleSentenceChange(e, lessonIndex, index, 'SentenceToBeTranslated')}
                    className={styles.input}
                  />
                </label>
  
                <label className={styles.label}>
                  Translated Sentence:
                  <input
                    type="text"
                    name={`TranslatedSentence_${index}`}
                    value={lesson.TranslatedSentence?.[index] || ''}
                    onChange={(e) => handleSentenceChange(e, lessonIndex, index, 'TranslatedSentence')}
                    className={styles.input}
                  />
                </label>
  
                <label className={styles.label}>
                  Additional Words:
                  <input
                    type="text"
                    name={`AdditionalWords_${index}`}
                    value={lesson.AdditionalWords?.[index] || ''}
                    onChange={(e) => handleSentenceChange(e, lessonIndex, index, 'AdditionalWords')}
                    className={styles.input}
                  />
                </label>
              </div>
            ))}
            {renderShowAdBeforeDropdown()}
  
            <button onClick={() => handleAddSentence(lessonIndex)} className={styles.button}>
              Add Sentence
            </button>
          </>
        );
      case 'Video_Questions':
        return (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Video URL:
                <input
                  type="text"
                  name="VideoUrl"
                  value={lesson.VideoQuestions?.VideoUrl || ''}
                  onChange={(e) => handleVideoQuestionChange(e, lessonIndex, undefined, undefined, 'videoUrl')}
                  className={styles.input}
                />
              </label>
            </div>
  
            {lesson.VideoQuestions?.Questions.map((question, qIndex) => (
              <div key={qIndex} className={styles.formGroup}>
                <label className={styles.label}>
                  Question {qIndex + 1}:
                  <input
                    type="text"
                    name="question"
                    value={question.question || ''}
                    onChange={(e) => handleVideoQuestionChange(e, lessonIndex, qIndex)}
                    className={styles.input}
                  />
                </label>
  
                <label className={styles.label}>
                  Correct Option:
                  <input
                    type="text"
                    name="correctOption"
                    value={question.correctOption || ''}
                    onChange={(e) => handleVideoQuestionChange(e, lessonIndex, qIndex, undefined, 'correctOption')}
                    className={styles.input}
                  />
                </label>
  
                {question.options.map((option, oIndex) => (
                  <label key={oIndex} className={styles.label}>
                    Option {oIndex + 1}:
                    <input
                      type="text"
                      name={`option_${oIndex}`}
                      value={option || ''}
                      onChange={(e) => handleVideoQuestionChange(e, lessonIndex, qIndex, oIndex)}
                      className={styles.input}
                    />
                  </label>
                ))}
              </div>
            ))}
  
            <button onClick={() => handleAddQuestion(lessonIndex)} className={styles.button}>
              Add Question
            </button>
            {renderShowAdBeforeDropdown()}
          </>
        );
      case 'Review':
        return (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Select Section:
                <select
                  name="ReviewSection"
                  value={lesson.ReviewSection || ''}
                  onChange={(e) => handleChange(e, lessonIndex)}
                  className={styles.input}
                >
                  <option value="">Select Section</option>
                  {lessons.map((sectionName, index) => (
                    <option key={index} value={sectionName}>
                      {sectionName}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label}>
                Select Component:
                <select
                  name="ReviewComponent"
                  value={lesson.ReviewComponent || ''}
                  onChange={(e) => handleChange(e, lessonIndex)}
                  className={styles.input}
                >
                  <option value="">Select Component</option>
                  <option value="Flashcards">Flashcards</option>
                  <option value="Matching">Matching</option>
                  <option value="MultipleChoice">Multiple Choice</option>
                  <option value="Memory">Memory</option>
                  <option value="SpeechPractice">Speech Practice</option>
                </select>
              </label>
            </div>
            {renderShowAdBeforeDropdown()}
          </>
        );
  
      case 'Flashcards':
      case 'Multiple Choice':
      case 'Matching':
      case 'Memory':
        return renderShowAdBeforeDropdown();
  
      default:
        return renderShowAdBeforeDropdown();
    }
  };
  

  const iconMapping: { [key: string]: any } = {
    Message: faEnvelope,
    Flashcards: faFileAlt,
    Memory: faBrain,
    Reading: faBook,
    Conversation: faCommentDots,
    SpeechPractice: faMicrophone,
    PhotoList: faCamera,
    Trivia: faQuestionCircle,
  };

  // Function to detect if changes have been made
  const hasChanges = () => {
    return JSON.stringify(tableData) !== JSON.stringify(initialTableData);
  };

  if (error) {
    return <div className={styles.container}><p>Error: {error}</p></div>;
  }

  return (
    <div className={styles.container}>
      <button onClick={() => navigate('/')} className={styles.backButton}>&larr; Back to Home</button>
      <h1 className={styles.title}>Section</h1>

      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Section:
            <select name="Identifier" value={formData.Identifier} onChange={handleChange} className={styles.input}>
              <option value="">Select Lesson</option>
              {lessons.map((lesson, index) => (
                <option key={index} value={lesson}>{lesson}</option>
              ))}
            </select>
          </label>
        </div>

        {/* Conditionally render the Save button if changes are detected */}
        {hasChanges() && (
          <button type="submit" className={styles.button}>Save</button>
        )}
      </form>

      <h2 className={styles.title}>Lessons</h2>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"lessons"}>
          {(provided) => (
            <div
              className={styles.lessonContainer}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {tableData.map((lesson, index) => (
                <Draggable key={index} draggableId={String(index)} index={index}>
                  {(provided) => (
                    <div
                      className={styles.lessonRow}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className={styles.lessonOrder}>
                        {index + 1}
                        <FontAwesomeIcon icon={iconMapping[lesson.Type] || faQuestionCircle} className={styles.icon} />
                      </div>
                      <div className={styles.lessonContent}>
                        <select
                          name="Type"
                          value={lesson.Type}
                          onChange={(e) => handleChange(e, index)}
                          className={styles.input}
                        >
                          <option value="">Select Lesson Type</option>
                          <option value="Message">Message</option>
                          <option value="Flashcards">Flashcards</option>
                          <option value="Matching">Matching</option>
                          <option value="MultipleChoice">Multiple Choice</option>
                          <option value="Memory">Memory</option>
                          <option value="Reading">Reading</option>
                          <option value="Conversation">Conversation</option>
                          <option value="SpeechPractice">SpeechPractice</option>
                          <option value="PhotoList">PhotoList</option>
                          <option value="Trivia">Trivia</option>
                          <option value="ItemDescribe">Item Describe</option>
                          <option value="VideoQuiz">Video Quiz</option>
                          <option value="ItemGuess">Item Guess</option>
                          <option value="FunQuiz">Fun Quiz</option>
                          <option value="Sentence_Translation">Sentence Translation</option>
                          <option value="Video_Questions">Video Questions</option>
                          <option value="Review">Review</option>
                        </select>
                        {renderAdditionalFields(lesson, index)}
                      </div>
                      <button
                        className={styles.deleteButton}
                        onClick={() => handleDeleteLesson(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              <button onClick={handleAddLesson} className={styles.button}>Add Lesson</button>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Modal isOpen={croppingDialogOpen} onRequestClose={() => setCroppingDialogOpen(false)}>
        {croppingLessonIndex !== null && tableData[croppingLessonIndex].imageFile && (
          <div className={styles.cropContainer}>
            <Cropper
              image={URL.createObjectURL(tableData[croppingLessonIndex].imageFile)}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
            <button onClick={handleCrop} className={`${styles.button} ${styles.cropButton}`}>
              Crop Image
            </button>
          </div>
        )}
      </Modal>

      {error && <div className={styles.error}>Error: {error}</div>}
    </div>
  );
};

export default Lesson;