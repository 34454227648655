import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styles from "./Trivia.module.css";
import Cropper, { Area } from "react-easy-crop";
import Modal from "react-modal";
import getCroppedImg from "../ImageCrop/cropImage";
import {
  fetchCourseData,
  fetchTriviaData,
  addTrivia,
  updateTrivia,
  deleteTrivia,
  uploadImage,
  callOpenAI,
} from "../API/API";
import { TriviaData } from "../API/types/Types";

const TriviaDetails: React.FC = () => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<TriviaData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [editingRow, setEditingRow] = useState<string | null>(null);

  const [formData, setFormData] = useState<TriviaData>({
    Identifier: uuidv4(),
    Level: "",
    Section: "",
    Difficulty: "1", // Added Difficulty
    Topic: "", // Added Topic
    Question: "",
    Options: ["", "", ""],
    Answer: "",
    ImageUrl: "",
    RandomnessLevel: "5",
  });

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppingDialogOpen, setCroppingDialogOpen] = useState<boolean>(false);
  const [croppedBlob, setCroppedBlob] = useState<File | null>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isGeneratingTopic, setIsGeneratingTopic] = useState<boolean>(false);
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sectionsData = await fetchCourseData(formData.Level);
        console.log("sectionsData:", sectionsData);

        // Extract the section name from sectionsData
        const triviaSection = sectionsData?.Vocabulary_List.S || "";
        console.log("triviaSection:", triviaSection);

        // Update formData.Section with the section name
        setFormData((prevData: any) => ({
          ...prevData,
          Section: triviaSection,
        }));

        // Fetch trivia questions using the triviaSection
        const triviaQuestions = await fetchTriviaData(triviaSection);
        console.log("triviaQuestions:", triviaQuestions);

        setTableData(triviaQuestions);
        setError(null);
      } catch (err: any) {
        console.log("Error fetching data:", err);
        setError(err.message);
        setTableData([]);
      }
    };

    if (formData.Level) {
      fetchData();
    }
  }, [formData.Level]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    identifier?: string
  ) => {
    const { name, value } = e.target;
    const [key, index] = name.split("_");

    if (identifier) {
      setTableData((prevData: TriviaData[]) =>
        prevData.map((item) =>
          item.Identifier === identifier
            ? {
                ...item,
                [key === "Options" ? key : name]:
                  key === "Options"
                    ? item.Options.map((opt: any, idx: number) =>
                        idx === parseInt(index, 10) ? value : opt
                      )
                    : value,
              }
            : item
        )
      );
    } else {
      if (key === "Options") {
        setFormData((prevData: any) => {
          const newOptions = [...prevData.Options];
          newOptions[parseInt(index, 10)] = value;
          return {
            ...prevData,
            Options: newOptions,
          };
        });
      } else {
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleGenerateRandomTopic = async () => {
    setIsGeneratingTopic(true);
    setError(null);
  
    try {
      const temperature = parseInt(formData.RandomnessLevel, 10) / 10; // Map randomness level to temperature
  
      const openaiPrompt = `Please provide a random topic or theme suitable for a trivia question. Return only the topic or theme in one sentence without any additional text. Generate a topic of randomness based on the randomness level ${temperature} (1 is extremely bland and basic, 10 is extremely incredibly most out of the world random)`;
  
      const response = await callOpenAI(openaiPrompt);
  
      if (response && response.response) {
        const topic = response.response.trim().replace(/^"|"$/g, "");
  
        setFormData((prevData) => ({
          ...prevData,
          Topic: topic,
        }));
      } else {
        setError("No response from OpenAI. Please try again.");
      }
    } catch (err) {
      console.error("Error generating topic:", err);
      setError("Error generating topic. Please try again.");
    } finally {
      setIsGeneratingTopic(false);
    }
  };
  

  const handleGenerateTrivia = async () => {
    setIsGenerating(true);
    setError(null);
  
    try {
      const { Topic, Difficulty } = formData;
  
      if (!Topic || Topic.trim() === "") {
        setError("Please generate a topic before generating the trivia question.");
        setIsGenerating(false);
        return;
      }
  
      const difficultyLevel = parseInt(Difficulty, 10);
  
      const openaiPrompt = `Generate a trivia question on the topic of "${Topic}" suitable for someone that is learning a language they are currently at level ${difficultyLevel} out of 15 in the language learning journey (1 being absolute beginner, 15 being a native speaker). Use words suitable for where they are at in the language so that they can understand. 
  
  - The "Question" should be related to the topic.
  - Provide exactly three multiple-choice "Options".
  - Provide the "Answer" as the correct answer.
  
  Output the response in JSON format with the following structure:
  
  {
    "Question": "Your trivia question?",
    "Options": ["Option A", "Option B", "Option C"],
    "Answer": "Correct answer"
  }
  
  Ensure that:
  - The response contains valid JSON only.
  - The "Options" array should include the correct answer and two incorrect answers.
  - All text is in Spanish
  `;
  
      const response = await callOpenAI(openaiPrompt);
      // console.log(response);
  
      if (response && response.response) {
        const responseText = response.response;
  
        try {
          // Parse the JSON response
          const jsonStart = responseText.indexOf("{");
          const jsonEnd = responseText.lastIndexOf("}");
          const jsonString = responseText.substring(jsonStart, jsonEnd + 1);
  
          const generatedData = JSON.parse(jsonString);
  
          setFormData((prevData) => ({
            ...prevData,
            Question: generatedData.Question || "",
            Options: generatedData.Options || ["", "", ""],
            Answer: generatedData.Answer || "",
          }));
        } catch (err) {
          console.error("Error parsing JSON:", err);
          setError(
            "Error parsing response. Please ensure the prompt was clear and retry."
          );
        }
      } else {
        setError("No response from OpenAI. Please try again.");
      }
    } catch (err) {
      console.error("Error generating trivia question:", err);
      setError("Error generating trivia question. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      let imageUrl = formData.ImageUrl;

      if (croppedBlob) {
        const newFileName = `${formData.Question.substring(0, 20)
          .replace(/\s+/g, "_")}_Image_${uuidv4()}.jpg`;

        imageUrl = await uploadImage(newFileName, croppedBlob);
      }

      // console.log(formData.Section.toString());
      const formattedData: TriviaData = {
        ...formData,
        Level: formData.Section.toString(),
        ImageUrl: imageUrl,
        Options: formData.Options.filter(
          (option: string) => option.trim() !== ""
        ),
      };

      await addTrivia(formattedData);

      setTableData((prevData: TriviaData[]) => [formattedData, ...prevData]);

      setError(null);

      // Reset form
      // Reset form
// Inside handleSubmit after adding trivia
setFormData({
  Identifier: uuidv4(),
  Level: formData.Level,
  Section: formData.Section,
  Difficulty: formData.Difficulty,
  RandomnessLevel: formData.RandomnessLevel, // Preserve RandomnessLevel
  Topic: "",
  Question: "",
  Options: ["", "", ""],
  Answer: "",
  ImageUrl: "",
});



      setImageFile(null);
      setCroppedBlob(null);
    } catch (err: any) {
      console.log("Error submitting form:", err);
      setError(err.message);
    }
  };

  const handleEditSubmit = async (identifier: string, level: string) => {
    const itemToUpdate = tableData.find(
      (item) => item.Identifier === identifier
    );

    if (!itemToUpdate) {
      setError("Item not found for editing");
      return;
    }

    try {
      let imageUrl = itemToUpdate.ImageUrl;

      if (croppedBlob) {
        const newFileName = `${itemToUpdate.Question.substring(0, 20)
          .replace(/\s+/g, "_")}_Image_${uuidv4()}.jpg`;

        imageUrl = await uploadImage(newFileName, croppedBlob);
      }

      const updatedData: TriviaData = {
        ...itemToUpdate,
        ImageUrl: imageUrl,
        Options: itemToUpdate.Options.filter((option: string) => option.trim() !== ""),
      };

      await updateTrivia(identifier, level, updatedData);

      setEditingRow(null);

      setTableData((prevData: TriviaData[]) =>
        prevData.map((item) =>
          item.Identifier === identifier ? { ...item, ...updatedData } : item
        )
      );
    } catch (err: any) {
      console.log("Error updating item:", err);
      setError(err.message);
    }
  };

  const handleDelete = async (identifier: string, level: string) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await deleteTrivia(identifier, level);

        setTableData((prevData: TriviaData[]) =>
          prevData.filter(
            (item) => item.Identifier !== identifier || item.Level !== level
          )
        );
      } catch (err: any) {
        console.log("Error deleting item:", err);
        setError(err.message);
      }
    }
  };

  const handleEdit = (identifier: string) => {
    setEditingRow(identifier);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setCroppingDialogOpen(true);
    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const handleCrop = async () => {
    if (imageFile && croppedAreaPixels) {
      try {
        const croppedBlobResult = await getCroppedImg(
          URL.createObjectURL(imageFile),
          croppedAreaPixels
        );

        // Create a new File from the cropped Blob
        const croppedFile = new File([croppedBlobResult], imageFile.name, {
          type: imageFile.type,
        });

        setCroppedBlob(croppedFile);
        setCroppingDialogOpen(false);

        // Generate a sanitized file name
        const fileExtension = imageFile.name.substring(
          imageFile.name.lastIndexOf(".")
        );
        const newFileName = `${formData.Question.substring(0, 20)
          .replace(/\s+/g, "_")}_Image_${uuidv4()}${fileExtension}`;

        setIsUploadingImage(true);
        // Upload the cropped image and get the URL
        const imageUrl = await uploadImage(newFileName, croppedFile);
        setFormData((prevData: any) => ({
          ...prevData,
          ImageUrl: imageUrl,
        }));
        setIsUploadingImage(false);
      } catch (err: any) {
        console.error("Error cropping or uploading image:", err);
        setError("Error processing the image. Please try again.");
        setIsUploadingImage(false);
      }
    }
  };

  const renderCellValue = (
    value: string | string[]
  ): JSX.Element | string => {
    if (Array.isArray(value)) {
      return (
        <>
          {value.map((option, index) => (
            <div key={index}>{option}</div>
          ))}
        </>
      );
    }

    return String(value);
  };

  return (
    <div className={styles.container}>
      <button onClick={() => navigate("/")} className={styles.backButton}>
        &larr; Back to Home
      </button>

      <h1 className={styles.title}>Trivia Questions</h1>

      <div className={styles.form}>
        <div className={styles.topRight}>
          <label className={styles.label}>
            Level:
            <select
              name="Level"
              value={formData.Level}
              onChange={handleChange}
              className={styles.input}
            >
              {[...Array(150).keys()].map((i) => (
                <option key={i + 1} value={(i + 1).toString()}>
                  {i + 1}
                </option>
              ))}
            </select>
          </label>

          <label className={styles.label}>
            Section:
            <input
              type="text"
              name="Section"
              value={formData.Section}
              readOnly
              className={styles.input}
            />
          </label>
        </div>
      
      </div>

      <h2>Add New Trivia Question</h2>

      <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.formGroup}>
  <label className={styles.label}>
    Difficulty:
    <select
      name="Difficulty"
      value={formData.Difficulty}
      onChange={handleChange}
      className={styles.input}
    >
      {[...Array(15).keys()].map((i) => (
        <option key={i + 1} value={(i + 1).toString()}>
          {i + 1}
        </option>
      ))}
    </select>
  </label>
</div>
<div className={styles.formGroup}>
  <label className={styles.label}>
    Topic:
    <input
      type="text"
      name="Topic"
      value={formData.Topic}
      readOnly
      className={styles.input}
    />
  </label>
  <div className={styles.formGroup}>
  <label className={styles.label}>
    Randomness:
    <select
      name="RandomnessLevel"
      value={formData.RandomnessLevel}
      onChange={handleChange}
      className={styles.input}
    >
      {[...Array(10).keys()].map((i) => (
        <option key={i + 1} value={(i + 1).toString()}>
          {i + 1}
        </option>
      ))}
    </select>
  </label>
</div>
  <button
    type="button"
    onClick={handleGenerateRandomTopic}
    className={styles.button}
    disabled={isGeneratingTopic}
  >
    {isGeneratingTopic ? "Generating..." : "Generate Random Topic"}
  </button>
</div>


        {/* Generate Trivia Button */}
        <button
          type="button"
          onClick={handleGenerateTrivia}
          className={styles.button}
          disabled={isGenerating}
        >
          {isGenerating ? "Generating..." : "Generate Trivia Question"}
        </button>

        {/* Display Generated Content in Form Fields */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Question:
            <input
              name="Question"
              value={formData.Question}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Options:
            <div className={styles.optionsGroup}>
              {formData.Options.map((option: string | number | readonly string[] | undefined, index: React.Key | null | undefined) => (
                <input
                  key={index}
                  type="text"
                  name={`Options_${index}`}
                  value={option}
                  onChange={handleChange}
                  className={styles.input}
                  required
                />
              ))}
            </div>
          </label>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Answer:
            <input
              type="text"
              name="Answer"
              value={formData.Answer}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </label>
        </div>

        {/* Image Upload */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Image:
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className={styles.input}
            />
          </label>
        </div>

        {/* Image Preview */}
        {isUploadingImage && <p>Uploading image...</p>}
        {formData.ImageUrl && (
          <img
            src={formData.ImageUrl}
            alt="Uploaded"
            className={styles.image}
          />
        )}

        <button type="submit" className={styles.button}>
          Add Trivia Question
        </button>
      </form>

      <table className={styles.table}>
      <thead>
  <tr>
    <th>Identifier</th>
    <th>Section</th>
    <th>Difficulty</th>
    <th>Topic</th>
    <th>Question</th>
    <th>Options</th>
    <th>Answer</th>
    <th>Image</th>
    <th>Edit / Delete</th>
  </tr>
</thead>

<tbody>
  {!formData.Section || tableData.length === 0 ? (
    <tr>
      <td colSpan={9} className={styles.noDataMessage}>
        {formData.Section
          ? "No trivia questions found for this section."
          : "No section available for this level."}
      </td>
    </tr>
  ) : (
    tableData.map((item) => (
      <tr key={item.Identifier}>
        <td>{item.Identifier}</td>
        <td>{item.Section}</td>
        <td>{item.Difficulty}</td>
        <td>{item.Topic}</td>
        <td>{item.Question}</td>
        <td>{renderCellValue(item.Options)}</td>
        <td>{item.Answer}</td>
        <td>
          {item.ImageUrl ? (
            <img
              src={item.ImageUrl}
              alt="Trivia"
              className={styles.image}
            />
          ) : (
            "No Image"
          )}
        </td>
        <td>
          {editingRow === item.Identifier ? (
            <div className={styles.buttonContainer}>
              <button
                onClick={() =>
                  handleEditSubmit(item.Identifier, item.Level)
                }
                className={styles.button}
              >
                Submit
              </button>
              <button
                onClick={() => setEditingRow(null)}
                className={styles.button}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className={styles.buttonContainer}>
              <button
                onClick={() => handleEdit(item.Identifier)}
                className={styles.button}
              >
                Edit
              </button>
              <button
                onClick={() =>
                  handleDelete(item.Identifier, item.Level)
                }
                className={styles.button}
              >
                Delete
              </button>
            </div>
          )}
        </td>
      </tr>
    ))
  )}
</tbody>

      </table>

      {/* Cropping Modal */}
      <Modal
        isOpen={croppingDialogOpen}
        onRequestClose={() => setCroppingDialogOpen(false)}
        contentLabel="Crop Image"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        {imageFile && (
          <div className={styles.cropContainer}>
            <Cropper
              image={URL.createObjectURL(imageFile)}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />

            <div className={styles.cropControls}>
              <input
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
                className={styles.zoomSlider}
              />
              <button
                onClick={handleCrop}
                className={`${styles.button} ${styles.cropButton}`}
                disabled={isUploadingImage}
              >
                {isUploadingImage ? "Uploading..." : "Crop & Upload Image"}
              </button>
            </div>
          </div>
        )}
      </Modal>

      {error && <div className={styles.error}>Error: {error}</div>}
    </div>
  );
};

export default TriviaDetails;
